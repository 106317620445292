module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-165839104-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"テレラボ","short_name":"テレラボ","start_url":"/","background_color":"#ffffff","theme_color":"#0287fe","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2bec9f5ceae2697f9dc043599a472e84"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
